import React from "react"

import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Masthead from "../components/masthead"
import Navigation from "../components/navigation"
import * as aboutStyles from "./about.module.css"

const AboutPage = ({ data }) => {
  console.log("index page data:", data)
  const title = data.site.siteMetadata.title
  const description = data.site.siteMetadata.description

  return (
    <Layout>
      <Seo title="Home" />
      <Navigation />

      <Masthead
        title={title}
        description={description}
        className={aboutStyles.container}
      />

      <section className="page-section" id="about">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">About us</h2>
            <h3 className="section-subheading text-muted">
              Our ambition is to be a portal where you can find car information
              of all car brands. Over time we will add more and more content.
            </h3>
          </div>
          <ul className="timeline">
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/img/about/1.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>2021</h4>
                  <h4 className="subheading">How it started</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    2021 we are live :-) We are car enthusiasts and want to find
                    as much information as possible to which we can link to
                  </p>
                </div>
              </div>
            </li>
            {/* <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/img/about/2.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>March 2011</h4>
                  <h4 className="subheading">An Agency is Born</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li> */}
            {/* <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/img/about/3.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>December 2012</h4>
                  <h4 className="subheading">Transition to Full Service</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li> */}
            {/* <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/img/about/4.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>July 2014</h4>
                  <h4 className="subheading">Phase Two Expansion</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li> */}
            <li className="timeline-inverted">
              <div className="timeline-image">
                <h4>
                  Come
                  <br />
                  Back
                  <br />
                  Soon!
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
        description
        copyright
        author
      }
    }
  }
`

export default AboutPage
